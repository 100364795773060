<template>
  <base-material-card
    icon="far fa-table-list"
    title="Riegenkarten"
    color="primary"
  >
    <p>Die Riegenkarten können parallel genutzt werden um die Wertungen aufzuschreiben. Außerdem dienen sie z.B. für Kampfrichter als Liste für die Reihenfolge der Turner.</p>
    <p>Die bereits eingegebenen Wertungen werden automatisch mit in die Liste gedruckt.</p>
    <v-btn block @click="riegenkarten(e, df, r)">Download</v-btn>
  </base-material-card>
</template>

<script>
import { usePdf } from '../../../plugins/pdf'

export default {
  name: 'riegenkarten',

  setup (props, context) {
    return {
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
